import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MetaInfo from "vue-meta-info";

// 导入全局样式
import "./assets/css/global.css";
// 引入阿里图标
import "./assets/fonts/iconfont.css";

//按需导入element-ui组件
import "./plugins/element";

import VueParticles from "vue-particles";
Vue.use(VueParticles);
Vue.use(MetaInfo);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
       document.dispatchEvent(new Event('render-event'))
        }
}).$mount("#app");
