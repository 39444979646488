<template>
  <div id="app" :class="media">
    <router-view></router-view>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'app',
  data(){
    return {
      media:''
    }
  },
  created() {
    this.deleteMsg()
  },
  created(){
    if(document.documentElement.clientWidth > 750){
        this.media= 'pc';
      }else{
          this.media= 'mobile';
      }
  },
  methods: {
    //去掉浏览器左下角的URL
    deleteMsg() {
      $("body").on('mouseover', 'a', function (e) {
        let $link = $(this)
        let href = $link.attr('href') || $link.data("href");
        // console.log($link, href)
        $link.off('click.chrome');
        $link.on('click.chrome', function () {
          window.location.href = href;
        })
          .attr('data-href', href)
          .css({ cursor: 'pointer' })
          .removeAttr('href');
      })
    }
  }
}

</script>

<style lang="scss">
@import "~@/assets/css/pc";
@import "~@/assets/css/mobile";

</style>
