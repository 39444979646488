<!--  -->
<template>
    <div class="footerwrapper">
        <div class="footer">
            <div class="footer-top">
                <div class="footer-top-left">
                    <div class="footer-top-left-wrapper">
                        <div class="title-top">
                            <router-link to="/index" class="title">首页</router-link>
                            <router-link to="/product" class="title">智慧工会</router-link>
                            <router-link to="/itemShow" class="title">客户案例</router-link>
                        </div>
                        <div class="title-bottom">
                            <router-link to="/news" class="title">新闻动态</router-link>
                            <router-link to="/about" class="title">关于我们</router-link>
                            <router-link to="/contactUs" class="title">云窗生活</router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-top-center">
                    <div class="footer-top-center-wrapper">
                        <div class="content" style="flex:1">
                            <el-image style="width: 60px; height: 60px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/footer/telephone.png"
                                fit="contain"></el-image>
                            <span>13951000924(李经理)<br /><br />13813882572(高经理)</span>
                        </div>
                        <div class="content" style="flex:1">
                            <el-image style="width: 60px; height: 60px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/footer/email.png"
                                fit="contain"></el-image>
                            <span>794528618@qq.com</span>
                        </div>
                        <div class="content" style="flex:2">
                            <el-image style="width: 60px; height: 60px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/footer/position.png"
                                fit="contain"></el-image>
                            <span>南京市建邺区新城科技园</span>
                        </div>
                    </div>
                </div>
                <div class="footer-top-right">
                    <div class="footer-top-right-wrapper">
                        <el-image style="width: 90px; height: 90px;margin-bottom: 10px;"
                            src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/footer/ercode.png"
                            fit="contain">
                        </el-image>
                        <span>李经理</span>
                    </div>
                    <div class="footer-top-right-wrapper">
                        <el-image style="width: 90px; height: 90px;margin-bottom: 10px;"
                            src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/footer/ercode2.png"
                            fit="contain">
                        </el-image>
                        <span>高经理</span>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <el-link :underline="false" href="https://beian.miit.gov.cn/#/Integrated/index">©2011-2022南京云窗科技有限公司
                    苏ICP备20043931号
                </el-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'foot',
    data() {
        return {

        }
    },
    methods: {

    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
</style>