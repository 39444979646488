import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import index from "../views/index/index";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    component: Home,
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: index,
        meta: {
          title: "首页",
          activeMenu: "/index"
        }
      },
      {
        path: "/product",
        component: () => import("@/views/product/product.vue"),
        meta: {
          title: "智慧工会",
          activeMenu: "/product"
        }
      },
      {
        path: "/news",
        name: "News",
        component: () => import("@/views/news/index.vue"),
        meta: {
          title: "客户案例",
          activeMenu: "/news"
        },

        children: [
          {
            path: 'newsDetail',
            component: () => import("@/views/news/newsDetail.vue")
          },
          {
            path: '/news',
            component: () => import("@/views/news/news.vue")
          },
        ]
      },
      {
        path: "/itemShow",
        name: "itemShow",
        component: () => import("@/views/itemShow/index.vue"),
        meta: {
          title: "新闻动态",
          activeMenu: "/itemShow"
        },
        children: [
          {
            path: 'itemDetail',
            component: () => import("@/views/itemShow/itemDetail.vue")
          },
          {
            path: '/itemShow',
            component: () => import("@/views/itemShow/itemShow.vue")
          },
        ]
      },
      {
        path: "/contactUs",
        component: () => import("@/views/contactUs/contactUs.vue"),
        meta: {
          title: "关于我们",
          activeMenu: "/contactUs"
        }
      },
      {
        path: "/about",
        component: () => import("@/views/about/about.vue"),
        meta: {
          title: "云窗生活",
          activeMenu: "/about"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

// 重复点击路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

//挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path === "/login") return next();
//   const tokenStr = window.sessionStorage.getItem("token");
//   if (!tokenStr) return next("/login");
//   next();
// });
export default router;
