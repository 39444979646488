import axios from "axios";
import qs from "qs";

export function request(config) {

    //创建axios实例
    let instance;
    instance = axios.create({
        baseURL: "https://yunchuangtech.com/apiWebsite/",
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
        timeout: 10000,
    });

    // 2.axios拦截器
    instance.interceptors.request.use(
        (config) => {
            if (config.method === "get") {
                //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
                config.paramsSerializer = function (params) {
                    return qs.stringify(params, {
                        arrayFormat: "repeat",
                    });
                };
            }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    return instance(config);
}
