<!--  -->

<template>
    <div class="home">
        <div class="banner">
            <div class="wrapper">
                <el-image
                    src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/banner_left.png"
                    fit="contain" class="banner_left">
                </el-image>
                <div class="content">
                    <span class="banner-tit">国内领先的工会信息化服务提供商</span>
                    <span class="banner-cont">专为用户提供智慧工会整体解决方案</span>
                </div>
            </div>
            <el-image
                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/banner_right.png"
                class="banner_right">
            </el-image>
        </div>
        <div class="union_bg">
            <div class="content_wrapper">
                <div class="title">云窗智慧工会</div>
                <div class="content">
                    <div style="line-height: 28px;">
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云窗智慧工会是在“互联网+工会"创新模式的基础上，通过资源整合、数据库建设、新媒体利用等方式，打造线上线下双向融合，集高效审批、精准帮扶、普惠服务、智慧提案、培训就业等功能于体的综合性服务平台。</span>
                        <br>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过平台提升工会管理效率和水平、改善服务职能、拓展工会组织发展空间、增强对职工的引领力和凝聚力。利用智慧工会平台和大数据分析，科学决策、精准服务，形成有问题、有反馈、能解决，真正实现民主管理、热情服务、广泛参与的良性循环。</span>
                        <br>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云窗科技充分利用好“互联网+”的整合性、高效性、创新性、交互性等优势，实现与互联网的跨界深度融合，拥抱智能化新技术，重塑新时代工会履行职能的新方式，拓展工会服务的新空间。</span>
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-title">国内领先的工会信息化服务提供商</div>
                    <div class="btn">
                        <el-button type="danger" @click="goMore">探索更多</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="myproduct">
            <div class="title">
                <span class="title_span">我们的产品</span>
                <span class="title_spant">- 智慧工会综合服务平台 -</span>
            </div>
            <el-image
                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/myproduct_left.png"
                fit="contain" class="myproduct_left">
            </el-image>
            <el-image
                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/myproduct_right.png"
                fit="contain" class="myproduct_right">
            </el-image>
        </div>
        <div class="myservice">
            <div class="wrapper">
                <div class="title">
                    <div style="display:flex;flex-direction: column;">
                        <span class="myservice_tit1">我们的服务</span>
                        <span class="myservice_tit2">- 全面的工会管理及运营服务 -</span>
                    </div>
                </div>
                <div class="serviceItem">
                    <el-card v-for="item in cardList" :key="item.id">
                        <div class="card_wrapper">
                            <el-image style="width: 30px" :src="item.url" fit="contain">
                            </el-image>
                            <div style="margin:12px 0;font-size: 16px;font-weight: 500;">{{ item.title }}</div>
                            <div style="font-size: 13px;font-weight: 300;">{{ item.content }}</div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
        <!-- <div class="cooperate">
            <div class="wrapper">
                <div class="title">
                    <div style="display:flex;flex-direction: column;">
                        <span class="cooperate_tit1">合作案例</span>
                        <span class="cooperate_tit2">- 助力工会工作向数字化转型，通过科技驱动工作模式革新
                            -</span>
                    </div>
                </div>
                <div class="carousel">
                    <el-carousel :interval="4000" type="card" :autoplay="false" @change="changeItem">
                        <el-carousel-item v-for="(item, index) in carouselList" :key="item.id"
                            @click.native="jumpToItemDetail(item.id, item.treeId)">
                            <div style="background: rgb(0 0 0);" class="imgWrapper">
                                <img :src="item.coverUrl" alt="图片丢失了" :title="item.title"
                                    :style="[{ opacity: index == currentIndex ? '0.6' : '1' }, { width: '100%', height: '100%' }]" />
                                <div class="imgContent" v-show="index == currentIndex">
                                    <div class="carousel_tit1">{{ item.title }}</div>
                                    <div class="carousel_tit2">
                                        {{ item.summary }}
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div> -->
        <div class="news newsHome">
            <div class="wrapper">
                <div class="title">
                    <div style="display:flex;flex-direction: column;">
                        <span style="text-align:center;font-size: 24px;font-weight: 600;margin-top: 30px;">新闻资讯</span>
                        <span style="font-size: 14px;font-weight: 300;margin-top: 15px;">- 及时了解到云窗科技的新闻动态以及行业的新鲜资讯
                            -</span>
                    </div>
                </div>
                <div class="newsItem">
                    <div class="cardDetail">
                        <el-image class="cardDetail_img" :src="firstNews.coverUrl">
                        </el-image>
                        <div class="cardContent">
                            <div style="color:#fe7e71">{{ firstNews.publishDate }}</div>
                            <div style="" class="cardContent_tit">{{ firstNews.title }}</div>
                            <div class="cardContent_tit2" style="">
                                {{ firstNews.summary }}</div>
                            <div>
                                <el-button type="danger" size="small"
                                    @click="jumpToDetail(firstNews.id, firstNews.treeId)">查看详情
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="cardList">
                        <el-card v-for="item in newsList" :key="item.id"
                            @click.native="jumpToDetail(item.id, item.treeId)">
                            <div class="cardListImgWrapper">
                                <img width="90" :src="item.coverUrl">
                            </div>
                            <div class="cardListContentWrapper">
                                <div style="color:#fe7e71;font-size: 14px;">{{ item.publishDate }}</div>
                                <div class="cardList_tit1">
                                    {{ item.title }}</div>
                                <div class="cardList_tit2">
                                    {{ item.summary }}</div>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="process">
            <div class="wrapper">
                <div class="title">
                    <div style="display:flex;flex-direction: column;">
                        <span class="process_tit1">服务流程</span>
                        <span
                            class="process_tit2">云窗科技专注于智慧工会领域，从功能设计、视觉设计、交互设计，到能力实现，运营维护，我们都拥有丰富的经验，可为用户提供智慧工会完整解决方案</span>
                    </div>
                </div>
                <div class="process_img">
                    <div class="process_img_wrapper">
                        <!-- fit="cover" -->
                        <el-image style="width: 100%;" class="line"
                            src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/line.png">
                        </el-image>
                        <div class="process_icon_wrapper1">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_analyse_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">需求分析及确认</span>
                        </div>
                        <div class="process_icon_wrapper2">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_idea_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">提供解决方案</span>
                        </div>
                        <div class="process_icon_wrapper3">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_product_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">产品能力实现</span>
                        </div>
                        <div class="process_icon_wrapper4">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_test_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">部署与调试</span>
                        </div>
                        <div class="process_icon_wrapper5">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_online_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">产品上线</span>
                        </div>
                        <div class="process_icon_wrapper6">
                            <el-image style="width: 36px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/process_market_icon.png"
                                fit="contain">
                            </el-image>
                            <span style="margin-top:10px;color:#fff;font-size:12px">协助运营</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import foot from '@/components/footer/footer.vue'
import { indexPage } from "@/api/home.js";
export default {
    metaInfo: {
        title: '工会-云窗科技', // set a title
        meta: [{                 // set meta
            name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
            content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
        }],
        link: [{                 // set link
            rel: 'asstes',
            href: 'http://yunchuangtech.com/'
        }]
    },
    data() {
        return {
            cardList: [{
                id: 1,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_activity.png',
                title: '工会活动策划服务',
                content: '活动组织 | 活动策划 | 文案设计 | 海报设计 | 活动运营'
            }, {
                id: 2,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_consult.png',
                title: '法律咨询顾问服务',
                content: '线上咨询 | 线上预约 | 线下咨询 | 委托办案 | 案件回访'
            }, {
                id: 3,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_heart.png',
                title: '心理咨询顾问服务',
                content: '线上咨询 | 线上预约 | 线下咨询 | 心理测试 | 问题回访'
            }, {
                id: 4,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_shop.png',
                title: '商城运营及管理服务',
                content: '商城推广 | 活动策划 | 海报设计 | 商品管理 | 财务统计'
            }, {
                id: 5,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_static.png',
                title: '数据统计及分析服务',
                content: '会员数据 | 帮扶数据 | 维权数据 | 活动数据 | 普惠服务数据'
            }, {
                id: 6,
                url: 'https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/icon_hand.png',
                title: '价值服务持续开发服务',
                content: '普惠服务功能扩展及开发 | 工会运营及管理功能扩展及开发'
            }],
            carouselList: [{
                id: 1,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/carousel_pic1.png",
                title: '华东理工大学智慧工会平台',
                content: '学校通过工会管理系统的建设，搭建网上工会，实现工会组织管理、人员管理、活动管理、业务管理的信息化、规范化、高效化，主动作为，积极作为，更好的服务职工群众'
            }, {
                id: 2,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/carousel_pic1.png",
                title: '华东理工大学智慧工会平台1',
                content: '学校通过工会管理系统的建设，搭建网上工会，实现工会组织管理、人员管理、活动管理、业务管理的信息化、规范化、高效化，主动作为，积极作为，更好的服务职工群众'
            }, {
                id: 3,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/carousel_pic1.png",
                title: '华东理工大学智慧工会平台2',
                content: '学校通过工会管理系统的建设，搭建网上工会，实现工会组织管理、人员管理、活动管理、业务管理的信息化、规范化、高效化，主动作为，积极作为，更好的服务职工群众'
            }],
            newsList: [{
                id: 1,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/cardlist_pic1.png",
                date: '2022-08-30',
                title: '南京云窗科技专注于智慧工会领域',
                content: '是国内领先的工会信息化服务提供商。公司致力于智慧工会产品研发、应用及运营，为用户提供智慧工会整体解决方案'
            }, {
                id: 2,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/cardlist_pic2.png",
                date: '2022-08-30',
                title: '南京云窗科技专注于智慧工会领域',
                content: '是国内领先的工会信息化服务提供商。公司致力于智慧工会产品研发、应用及运营，为用户提供智慧工会整体解决方案'
            }, {
                id: 3,
                url: "https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/index/cardlist_pic3.png",
                date: '2022-08-30',
                title: '南京云窗科技专注于智慧工会领域',
                content: '是国内领先的工会信息化服务提供商。公司致力于智慧工会产品研发、应用及运营，为用户提供智慧工会整体解决方案'
            }],
            paramsInfo: {
                pageSize: 20,
                pageNum: 1,
                type: null
            },
            firstNews: {},
            currentIndex: 0
        }
    },
    components: {
        foot
    },
    methods: {
        changeItem(e) {
            this.currentIndex = e
        },
        goMore() {
            this.$router.push({
                path: '/product'
            })
        },
        async getData(type) {
            if (type == 1) {
                this.paramsInfo.type = type
                let { data: res } = await indexPage(this.paramsInfo);
                res.data.records.forEach(val => {
                    val.publishDate = val.publishDate.slice(0, 10)
                });
                this.firstNews = res.data.records.slice(0, 1)[0]
                this.newsList = res.data.records.slice(1, 4)
            } else if (type == 2) {
                this.paramsInfo.type = type
                let { data: res } = await indexPage(this.paramsInfo);
                this.carouselList = res.data.records
            }
        },
        jumpToDetail(id, treeId) {
            this.$router.push({ path: "/news/newsDetail", query: { id, categoryId: treeId } })
        },
        jumpToItemDetail(id, treeId) {
            this.$router.push({ path: "/itemShow/itemDetail", query: { id, categoryId: treeId } })
        }
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
        this.getData(1)
        this.getData(2)
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>

<style scoped lang="scss">
/* @import url(); 引入css类 */
</style>