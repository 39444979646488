<template>
  <div class="home">
    <el-container class="home_content">
      <el-header height="60px" class="headerBox">
        <div class="home_title" @click="goIndex">
          <el-image style="width: 100px; height: 60px"
            src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/logo.png" fit="contain">
          </el-image>
        </div>
        <el-menu background-color="#fff" class="headers" text-color="#000000" active-text-color="#f76151" router
          mode="horizontal" :default-active="activeMenu">
          <el-menu-item :index="'/' + item.path" v-for="item in menuList" :key="item.id">
            <template slot="title">
              <span>{{ item.authName }}</span>
            </template>
          </el-menu-item>
        </el-menu>
        <div class="more-icon" @click="openMenu">
          <i class="el-icon-more"></i>
        </div>
        <div class="header-right">
          <!-- <i class="icon iconfont icon-sousuo" style="cursor: pointer;"></i> -->
        </div>
        <div class="left-slide" :class="isOpen ? 'active' : ''" ref="leftSide" @click="closeMenu">
          <div class='slide-box' @click.stop="stopMu">
            <div class="list" @click="openNav(item)" v-for="item in menuList" :key="item.id"
              :class="activeMenu == '/' + item.path ? 'active' : ''">
              <span>{{ item.authName }}</span>
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <transition mode="out-in">
          <keep-alive exclude="news,item">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      // 被激活的链接地址
      isOpen: false,
      menuList: [{
        "id": 1,
        "authName": "首页",
        "path": "index",
      }, {
        "id": 3,
        "authName": "智慧工会",
        "path": "product",
      }, {
        "id": 4,
        "authName": "客户案例",
        "path": "itemShow",
      }, {
        "id": 5,
        "authName": "新闻动态",
        "path": "news",
      }, {
        "id": 2,
        "authName": "关于我们",
        "path": "about",
      }, {
        "id": 6,
        "authName": "云窗生活",
        "path": "contactUs",
      }],
    }
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: '/index'
      })
    },
    stopMu() {

    },
    openMenu() {
      this.isOpen = true
    },
    closeMenu() {
      this.isOpen = false
    },
    openNav(item) {
      this.$router.push({
        path: `/${item.path}`
      })
      this.isOpen = false
    }
  },
  watch: {
    '$route'(val) {
      if (!document.getElementsByClassName('el-main') || !document.getElementsByClassName('el-main').length) {
        return
      }
      document.getElementsByClassName('el-main')[0].scrollTop = 0
    }
  },
  computed: {
    // 我们使用计算属性来获取到当前点击的菜单的路由路径，然后设置default-active中的值
    // 使得菜单在载入时就能对应高亮
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  created() {
  }
}

</script>
<style scoped lang="scss">

</style>
